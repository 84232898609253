<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('car_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @page-change="pageChange"
    ></x-table>
    <Modal v-model="carDetail.show" title="司机详情" :width="600">
      <Form :label-width="100">
        <Row>
          <Col span="12">
            <FormItem label="姓名：">
              <span>{{ carDetail.info.driverName }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="性别：">
              <span>{{ carDetail.info.dreverSexName }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="电话号码：">
              <span>{{ carDetail.info.driverPhone }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="邮箱：">
              <span>{{ carDetail.info.driverEmail }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="所属公司：">
              <span>{{ carDetail.info.driverCompanyName }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="工作证">
              <span>{{ carDetail.info.driverWordNo }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="驾驶证：">
              <span>{{ carDetail.info.driverNo }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="生日：">
              <span>{{
                carDetail.info.driverBirthday
                  ? carDetail.info.driverBirthday.split(" ")[0]
                  : ""
              }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="工作状态：">
              <span>{{ carDetail.info.driverStatus }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="司机从业资格有效期：">
              <span>{{
                carDetail.info.driverNoValidPeriod
                  ? carDetail.info.driverNoValidPeriod.split(" ")[0]
                  : ""
              }}</span>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          {
            title: "头像",
            width: 100,
            align: "center",
            render: (h, { row }) => {
              return (
                <div style="width:60px;height:60px;padding:4px">
                  <img
                    style="width:100%;height:100%"
                    src={this.getImgUrl(row.servicePath, row.driverAvatar)}
                  />
                </div>
              );
            },
          },
          {
            title: "姓名",
            align: "center",
            key: "driverName",
          },
          {
            title: "性别",
            align: "center",
            key: "driverSexName",
          },
          {
            title: "电话号码",
            align: "center",
            width: 200,
            key: "driverPhone",
          },
          {
            title: "工作证",
            align: "center",
            key: "driverWordNo",
          },
          {
            title: "所属公司",
            align: "center",
            key: "driverCompanyName",
          },
          {
            title: "邮箱",
            align: "center",
            key: " driverEmail",
          },
          {
            title: "从业资格有效期",
            align: "center",
            key: "driverNoValidPeriod",
          },
          {
            title: "工作状态",
            align: "center",
            width: 150,
            key: "driverStatus",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("car_view") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      查看详情
                    </a>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          width: 200,
          filterBox: [
            {
              conditionName: "姓名",
              component: "input",
              field: "driverName",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      search_data: {},
      carDetail: {
        show: false,
        info: {},
      },
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },

    getList() {
      if (!this.CA("car_check")) return;
      this.table.loading = true;
      this.$post(this.$api.SPORT_STATUS.DRIVERS, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    checkInfo(row) {
      this.carDetail = {
        show: true,
        info: row,
      };
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>